import React, { FunctionComponent, useCallback, useState } from "react";
import { WithData } from "../../../types";
import { Bank, PendingOrder } from "../../../types/Order";
import { PickBank } from "../../utils/pick-bank";
import { Field } from "../../utils/field";
import styles from './approve-order-receipt.module.scss';
import { Button } from "../../utils/button";
import {
    approveReceipt,
    cleanNextStatusOrder
} from "../../../store/orderSlice";
import { useAppDispatch } from "../../../hooks";

export const ApproveOrderReceipt: FunctionComponent<WithData<PendingOrder>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const [bank, setBank] = useState<Bank>();
    const [externalId, setExternalId] = useState<string>();

    const goBack = useCallback(() => {
        dispatch(cleanNextStatusOrder())
    }, []);

    const approve = useCallback(() => {
        dispatch(approveReceipt(externalId!, bank!))
    }, [bank, externalId])

    return (
        <div className={styles.approveOrderReceipt}>
            <PickBank value={bank} onChange={setBank}/>

            <Field
                label='External Id'
                placeholder="E31872495202211221713wwJy8BwFssS"
                value={externalId}
                onChange={setExternalId}
            />

            <div className={styles.actions}>
                <Button
                    label='Back'
                    color='warning'
                    appearance='outline'
                    onClick={goBack}/>
                <Button label='Approve' disabled={!bank || !externalId} onClick={approve}/>
            </div>
        </div>
    )
}