import { LoginRequest, User } from "../types/User";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./index";
import UserService from "../services/UserService";
import { Api } from "../services/Api";
import { showAlert } from "./notificationSlice";
import { ChangePasswordDto } from "../types/ChangePasswordDto";
import { Websocket } from "../services/Websocket";
import { delay, userFromToken } from "../utils";

export const TOKEN_KEY = 'token';

enum LoginStateEnum {
    started = 1,
    notStarted = 2
}

interface AuthState {
    loggedUser: User | null,
    loginState: LoginStateEnum,
    tokenExpiresAt: number | null;
    passwordMustBeChanged: boolean;
    changePasswordDialogIsOpened: boolean;
    error: string | null
}

const initialState: AuthState = {
    loggedUser: null,
    loginState: LoginStateEnum.notStarted,
    tokenExpiresAt: null,
    passwordMustBeChanged: false,
    changePasswordDialogIsOpened: false,
    error: ''
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoggedUser: (state, action: PayloadAction<User | null>) => {
            state.loggedUser = action.payload;
        },
        updateStateLogin: (state, action: PayloadAction<LoginStateEnum>) => {
            state.loginState = action.payload;
        },
        updateError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        updateTokenExpiration: (state, action: PayloadAction<number | null>) => {
            state.tokenExpiresAt = action.payload;
        },
        setPasswordMustBeChanged: (state, action: PayloadAction<boolean>) => {
            state.passwordMustBeChanged = action.payload;
        },
        openChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = true;
        },
        closeChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = false;
        }
    }
});

const { updateLoggedUser, updateStateLogin, updateTokenExpiration, setPasswordMustBeChanged } = authSlice.actions;
export const { updateError, openChangePasswordDialog, closeChangePasswordDialog } = authSlice.actions;

export const renewToken = (): AppThunk => () =>
    UserService.renewToken();

export const create2fa = (obj: LoginRequest): AppThunk => dispatch =>
    UserService.create2FA(obj)
        .then(() => dispatch(updateStateLogin(LoginStateEnum.started)))
        .catch(({ message }) => dispatch(updateError(message)));

export const login = (obj: LoginRequest): AppThunk => dispatch =>
    UserService.login(obj)
        .then(({ token, passwordMustBeChanged }) => {
            dispatch(updateToken(token));
            dispatch(setPasswordMustBeChanged(passwordMustBeChanged))

            if (passwordMustBeChanged)
                dispatch(openChangePasswordDialog())
        })
        .catch(({ message }) => dispatch(updateError(message)));

let timeout: NodeJS.Timeout;
export const updateToken = (token: string): AppThunk => async dispatch => {
    localStorage.setItem(TOKEN_KEY, token);
    Api.setToken(token);
    Websocket.updateToken(token);

    const user = userFromToken(token)!;

    const expiration = (user as any).exp;
    if (expiration <= Date.now()) {
        dispatch(logoff());
        return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(logoff()), expiration - Date.now())

    dispatch(updateTokenExpiration((user as any).exp));
    dispatch(updateLoggedUser(user));

    await Websocket.openWebsocketConnection();
}

export const updateOfflineToken = (token: string): AppThunk => dispatch => {
    const user = userFromToken(token)!;
    dispatch(updateLoggedUser(user));
    dispatch(updateTokenExpiration(null));
}

export const loginFromStorage = (): AppThunk => (dispatch, getState) => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token)
        return;

    let hasConnection = getState().appState.hasConnection;
    const waitForConnection = async (): Promise<void> => {
        if (hasConnection) {
            return;
        }

        await delay(10 * 1000);
        hasConnection = getState().appState.hasConnection;
        return waitForConnection();
    }

    if (!hasConnection)
        dispatch(updateOfflineToken(token));

    waitForConnection()
        .then(() => dispatch(updateToken(token)));
};

export const forceLogoff = (): AppThunk => dispatch => {
    localStorage.setItem(TOKEN_KEY, '');
    dispatch(logoff())
}

export const logoff = (): AppThunk => dispatch => {
    Api.setToken('');
    Websocket.updateToken('');

    dispatch(updateTokenExpiration(null))
    dispatch(updateLoggedUser(null));
    dispatch(updateStateLogin(LoginStateEnum.notStarted));
}

export const changePassword = (changePasswordForm: ChangePasswordDto): AppThunk => dispatch =>
    UserService.changePassword(changePasswordForm)
        .then(() => {
            dispatch(closeChangePasswordDialog());
            dispatch(showAlert('Password changed!'));
        })

export const getLoggedUser = (state: RootState): User | null => state.authState.loggedUser;
export const getLoginIsStarted = (state: RootState): boolean => state.authState.loginState === LoginStateEnum.started;
export const getErrorAuth = (state: RootState): string | null => state.authState.error;
export const getTokenExpiresAt = (state: RootState): number | null => state.authState.tokenExpiresAt;
export const getChangePasswordIsOpened = (state: RootState): boolean => state.authState.changePasswordDialogIsOpened;
export const getPasswordMustBeChanged = (state: RootState): boolean => state.authState.passwordMustBeChanged;

export default authSlice.reducer;
