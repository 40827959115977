import { Action, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import authState, { loginFromStorage } from '../store/authSlice';
import appState, { setupAppState } from '../store/appSlice';
import notificationState from "./notificationSlice";
import settingsState from './settingsSlice';
import searchState from './searchSlice';
import operatorState, { setupOperatorState } from "./operatorSlice";
import clientState, { setupClientState } from './clientSlice';
import mySelfState, { setupMySelfState } from "./mySelfSlice";
import orderState, { setupOrderState } from './orderSlice';
import { Api } from "../services/Api";
import { delay } from "../utils";
import { setupSettingsState } from "./settingsSlice";

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        appState,
        authState,
        notificationState,
        searchState,
        clientState,
        settingsState,
        operatorState,
        mySelfState,
        orderState
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export type AppThunkDispatcher = ThunkDispatch<RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export const setupApplication = async (): Promise<void> => {
    Api.setDispatcher(store.dispatch);

    store.dispatch(setupAppState());
    await delay(300);

    store.dispatch(loginFromStorage());
    store.dispatch(setupClientState());
    store.dispatch(setupSettingsState());
    store.dispatch(setupOperatorState());
    store.dispatch(setupMySelfState());
    store.dispatch(setupOrderState());
}