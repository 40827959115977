import { getUrlGenerator } from "../utils";
import { Api } from "./Api";
import { Client, UpdateClientDto } from "../types/Client";

const baseUrl = process.env.REACT_APP_CLIENT_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class ClientService {
    public static update = (dto: UpdateClientDto): Promise<Client> =>
        Api.patch(generateUrl(dto.id), dto);
}
