import React, { FunctionComponent, useCallback } from "react";
import { WithData } from "../../../types";
import { Order } from "../../../types/Order";
import styles from "./order-table.module.scss";
import { useAppDispatch } from "../../../hooks";
import { selectOrderId } from "../../../store/orderSlice";
import {
    getOrderHashId,
    getOrderStatus,
    getOrderStatusStyle,
    getPaymentMethodLabel
} from "../../../utils/orderHelpers";
import { formatPriceAmount } from "../../../utils";

interface Props {
    data: Order[];
    emptyMessage: string
}

export const OrderTable: FunctionComponent<Props> = ({data, emptyMessage}) => {
    if (!data.length)
        return <div className={styles.orderTable}>{emptyMessage}</div>

    return (
        <div className={styles.orderTable}>
            <table>
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Price</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                    <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                {data.map(x => <OrderItem data={x} key={x.id}/>)}
                </tbody>
            </table>
        </div>
    )
}

const OrderItem: FunctionComponent<WithData<Order>> = ({data}) => {
    const dispatch = useAppDispatch();
    const openOrder = useCallback(() => {
        dispatch(selectOrderId(data.id));
    }, [dispatch]);

    return (
        <tr onClick={openOrder}>
            <td data-title='Id'>{getOrderHashId(data)}</td>
            <td data-title='Price'>{formatPriceAmount(data.amount)}</td>
            <td data-title='Payment Method'>{getPaymentMethodLabel(data)}</td>
            <td data-title='Status' className={getOrderStatusStyle(data)}>{getOrderStatus(data)}</td>
            <td data-title='Created At'>{data.createdAt.toLocaleString()}</td>
        </tr>
    )
}

