import React, { FunctionComponent, useCallback } from "react";
import { WithData } from "../../types";
import {
    CreatedOrder, DeclinedOrder,
    Order,
    OrderStatus,
    PaidManuallyApprovedOrder,
    PaidOrder,
    PaidPixOrder,
    PaymentType, PendingOrder
} from "../../types/Order";
import styles from "./order-detail.module.scss";
import { Label } from "../utils/label";
import { StorageImage } from "../utils/storage-image";
import { useDispatch } from "react-redux";
import { NextStatusOrder, setNextStatusSelectedOrder } from "../../store/orderSlice";
import { Button } from "../utils/button";
import { formatPriceAmount } from "../../utils";
import { getOrderStatus, getOrderStatusStyle, getPaymentMethodLabel } from "../../utils/orderHelpers";

export const OrderDetailedInfo: FunctionComponent<WithData<Order>> = ({ data }) => {
    const getPaidComponent = (): JSX.Element => {
        const paidOrder = data as PaidOrder;
        const dicPaidComponents: Record<PaymentType, () => JSX.Element> = {
            [PaymentType.ted]: () => <OrderPaidWithTed data={data as PaidManuallyApprovedOrder}/>,
            [PaymentType.pix]: () => <OrderPaidWithPix data={data as PaidPixOrder}/>
        };

        return dicPaidComponents[paidOrder.payment.type]();
    }

    const dicComponentsByState: Record<OrderStatus, () => JSX.Element> = {
        [OrderStatus.created]: () => <OrderCreated data={data as CreatedOrder}/>,
        [OrderStatus.pending]: () => <OrderPending data={data as PendingOrder}/>,
        [OrderStatus.declined]: () => <OrderDeclined data={data as DeclinedOrder}/>,
        [OrderStatus.paid]: getPaidComponent
    }

    return dicComponentsByState[data.status]();
}

const OrderCreated: FunctionComponent<WithData<CreatedOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data}/>
        </div>
    </div>
)

const OrderDeclined: FunctionComponent<WithData<DeclinedOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data}/>
            <Label text='Reason' content={data.payment.motive}/>
        </div>
        <div className={styles.receipt}>
            <StorageImage imageId={data.payment.receiptId}/>
        </div>
    </div>
)

const OrderPending: FunctionComponent<WithData<PendingOrder>> = ({ data }) => {
    const dispatch = useDispatch();
    const setNextStatus = useCallback((nextStatus: NextStatusOrder) => {
        dispatch(setNextStatusSelectedOrder(nextStatus));
    }, [dispatch]);

    return (
        <>
            <div className={styles.content}>
                <div className={styles.info}>
                    <BasicInfo data={data}/>
                </div>
                <div className={styles.receipt}>
                    <StorageImage imageId={data.payment.receiptId}/>
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    label='Decline'
                    color='error'
                    appearance='outline'
                    onClick={() => setNextStatus(NextStatusOrder.WillDecline)}/>
                <Button label='Approve' onClick={() => setNextStatus(NextStatusOrder.WillApprove)}/>
            </div>
        </>
    );
}

const OrderPaidWithPix: FunctionComponent<WithData<PaidPixOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data}/>
            <PaidInfo data={data}/>
        </div>
    </div>
)

const OrderPaidWithTed: FunctionComponent<WithData<PaidManuallyApprovedOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data}/>
            <PaidInfo data={data}/>
        </div>
        <div className={styles.receipt}>
            <StorageImage imageId={data.payment.receiptId}/>
        </div>
    </div>
)

const BasicInfo: FunctionComponent<WithData<Order>> = ({ data }) => {
    return <>
        <Label text='USDT Address' content={data.address}/>
        <Label text='Amount' content={formatPriceAmount(data.amount)}/>
        <Label text='Status' content={getOrderStatus(data)} className={getOrderStatusStyle(data)}/>
        <Label text='Created At' content={data.createdAt.toLocaleString()}/>
    </>;
}

const PaidInfo: FunctionComponent<WithData<PaidOrder>> = ({ data }) => (
    <>
        <Label text='Bank' content={data.payment.bank}/>
        <Label text='Payment Method' content={getPaymentMethodLabel(data)}/>
        <Label text='External Id' content={data.payment.externalId}/>
        <Label text='Payment Date' content={data.payment.statusDate.toLocaleString()}/>
    </>
)