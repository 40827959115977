import {FunctionComponent} from "react";
import {Dialog} from "../dialog";
import styles from './confirm.module.scss';
import React from "react";

interface Props {
    isOpen: boolean;
    title: string;
    subtitle?: string;
    text: string;
    onConfirmed: Function;
    onClose: Function;
    textConfirm?: string;
    actionColor?: 'primary' | 'error'
}

export const Confirm: FunctionComponent<Props> = props => {
    return (
        <Dialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.title}
            subtitle={props.subtitle}
            action={props.textConfirm || 'Confirm'}
            onAction={props.onConfirmed}
            actionColor={props.actionColor}
        >
            <div className={styles.bodyConfirm}>{props.text}</div>
        </Dialog>
    )
}
