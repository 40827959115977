import { useDispatch, useSelector } from "react-redux";
import {
    cleanSelectedOrder,
    getNextStatusSelectedOrder,
    getSelectedOrder,
    NextStatusOrder,
} from "../../store/orderSlice";
import React, { FunctionComponent, useCallback } from "react";
import { OrderDetailedInfo } from "./OrderDetailInfo";
import { ApproveOrderReceipt } from "./approve-order-receipt";
import { RejectOrderReceipt } from "./reject-order-receipt";
import { PendingOrder } from "../../types/Order";
import { Dialog } from "../utils/dialog";

export const OrderDetail = () => {
    const dispatch = useDispatch();
    const selectedOrder = useSelector(getSelectedOrder);

    const closePopUp = useCallback(() => {
        dispatch(cleanSelectedOrder());
    }, []);

    return (
        <Dialog isOpen={!!selectedOrder} title={`Order ${selectedOrder?.id}`} onClose={closePopUp}>
            <Content/>
        </Dialog>
    )
}

const Content: FunctionComponent = () => {
    const selectedOrder = useSelector(getSelectedOrder);
    const nextStatusOrderSelected = useSelector(getNextStatusSelectedOrder);

    if (!selectedOrder)
        return null;

    if (!nextStatusOrderSelected)
        return <OrderDetailedInfo data={selectedOrder}/>

    const Component = {
        [NextStatusOrder.WillApprove]: ApproveOrderReceipt,
        [NextStatusOrder.WillDecline]: RejectOrderReceipt
    }[nextStatusOrderSelected];

    return <Component data={selectedOrder as PendingOrder}/>;
}
