export enum Status {
    pendingApproval = 'pending-approval',
    active = 'active',
    frozen = 'frozen',
}

export enum ClientType {
    individual = 'individual',
    business = 'business',
}

export interface IndividualDocument {
    number: string;
    frontId: string;
    backId: string;
}

export interface BusinessDocument {
    number: string;
    imageId: string;
    socialContractImageId: string;
}

export interface Address {
    postalCode: string;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement?: string;
}

export interface CompanyInfo {
    name: string;
    document: BusinessDocument;
}

export interface Client {
    id: string;
    clientType: ClientType;
    name: string;
    selfieId: string;
    document: IndividualDocument;
    userId: string;
    status: Status;
    email: string;
    phoneNumber: string;
    address: Address;
    companyInfo?: CompanyInfo;
    createdAt: Date;
    updatedAt: Date;
    receiveEmails: boolean;
    birthOrStartDate: Date;
}

export type UpdateClientDto = { id: string } & Partial<Client>;