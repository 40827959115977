import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './client.module.scss';
import { ClientDetails } from './client-details';
import { useSelector } from "react-redux";
import { getClient, updateSelectedClientId } from "../../../store/clientSlice";
import { useAppDispatch } from "../../../hooks";
import { Breadcrumb, BreadcrumbItem } from "../../utils/breadcrumb";
import { ClientProfile } from "./client-profile";

export const Client = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const client = useSelector(getClient(id));

    useEffect(() => {
        dispatch(updateSelectedClientId(id))
    }, [id])

    if (!client)
        return <div>loading...</div>;

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Clients', link: '/clients' },
        { label: client.name }
    ];

    return (
        <div>
            <Breadcrumb data={breadcrumb}/>
            <div className={styles.clientBody}>
                <ClientProfile/>
                <ClientDetails/>
            </div>
        </div>
    );
};
