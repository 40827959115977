import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";
import { cleanupMySelfState, fetchMySelf, getMySelf, updateMySelfInfo } from "../../../store/mySelfSlice";
import { useEffect } from "react";
import { Card } from "../../utils/card";
import { HeaderTitle } from "../../utils/header-title";
import { BaseFormUser } from "../base-form-user";

export const MySelf = () => {
    const dispatch = useAppDispatch();
    const currentUser = useSelector(getMySelf);

    useEffect(() => {
        dispatch(fetchMySelf());
        return () => {
            dispatch(cleanupMySelfState())
        };
    }, [dispatch]);

    if (!currentUser)
        return <div>loading...</div>;

    return (
        <Card>
            <HeaderTitle title='My information'/>
            <BaseFormUser
                value={currentUser}
                labelAction='Update'
                onAction={(newVal) => dispatch(updateMySelfInfo({ ...currentUser, ...newVal }))}
            />
        </Card>
    )
}
