import React, { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks";
import { CreateUserDto, UpdateUserDto } from "../../../../types/User";
import { Card } from "../../../utils/card";
import { BaseFormUser } from "../../base-form-user";
import { createOperator } from "../../../../store/operatorSlice";
import { HeaderTitle } from "../../../utils/header-title";

export const AddOperator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onAction = useCallback((newVal: CreateUserDto | UpdateUserDto) => {
        dispatch(createOperator(newVal))
            .then(() => navigate('/operators'))
    }, [navigate]);

    return (
        <Card>
            <HeaderTitle title='Create Operator'/>
            <BaseFormUser
                labelAction='Create'
                onAction={onAction}
            />
        </Card>
    );
};
