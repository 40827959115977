import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { Client } from "../types/Client";
import { Order, OrderStatus } from "../types/Order";
import { formatPriceAmount } from "../utils";

interface SearchState {
    searchTerm: string | undefined;
}

const initialState: SearchState = {
    searchTerm: undefined,
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearchTerm: (state, action: PayloadAction<string | undefined>) => {
            state.searchTerm = action.payload;
        },
    },
});

export const {updateSearchTerm} = searchSlice.actions;

export const getHasSearchTerm = (state: RootState): boolean => !!state.searchState.searchTerm;
export const getClientsMatchTerm = (state: RootState): Client[] => {
    if (!state.clientState.lstClients.length)
        return [];

    const term = state.searchState.searchTerm?.toLowerCase();
    if (!term)
        return state.clientState.lstClients.slice(0, 4);
    const numericTerm = term.replace(/\D/g, '');

    let results: Client[] = [];
    for (const client of state.clientState.lstClients) {
        if (results.length >= 4)
            break;

        const {name, document, companyInfo, email, phoneNumber} = client;
        const fullName = `${name} ${companyInfo?.name || ''}`.toLowerCase();
        const matchName = fullName.includes(term);

        const matchEmail = email.toLowerCase().includes(term);
        const matchPhoneNumber = phoneNumber.replace(/\D/g, '').includes(numericTerm);
        const matchDocument = document.number.replace(/\D/g, '').includes(numericTerm);

        if (!matchName && !matchEmail && !matchPhoneNumber && !matchDocument)
            continue;

        results = [...results, client];
    }

    return results;
}

export const getOrderMatchTerm = (state: RootState): Order[] => {
    const lst = state.orderState.lstOrders;
    if (!lst.length)
        return [];

    const term = state.searchState.searchTerm?.toLowerCase();
    if (!term)
        return [];

    let results: Order[] = [];
    for (const order of lst) {
        const {id, amount, address, status} = order;

        const matchId = id.toLowerCase().includes(term);
        const matchAmount = formatPriceAmount(amount).toLowerCase().includes(term);
        const matchAddress = address.toLowerCase().includes(term);

        let matchesPaid = false;
        if (status === OrderStatus.paid) {
            const {bank, externalId} = order.payment;

            const matchesBank = bank.toLowerCase().includes(term);
            const matchesExternalId = externalId.toLowerCase().includes(term);

            matchesPaid = matchesBank || matchesExternalId;
        }

        let matchesNotApproved = false;
        if (status === OrderStatus.declined) {
            const {motive} = order.payment;
            matchesNotApproved = motive.toLowerCase().includes(term);
        }

        if(!matchId && !matchAmount && !matchAddress && !matchesPaid && !matchesNotApproved)
            continue;

        results = [...results, order];
    }

    return results;
}

export default searchSlice.reducer;
