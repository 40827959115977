import { FunctionComponent, useEffect, useState } from "react";
import StorageService from "../../../services/StorageService";

interface Props {
    imageId?: string,
}

export const StorageImage: FunctionComponent<Props> = props => {
    const [base64, setBase64] = useState('');

    useEffect(() => {
        if (!props.imageId)
            return;

        StorageService.getFile(props.imageId).then(setBase64)
    }, [props.imageId])

    if (!base64)
        return null

    return <img src={base64} alt=""/>
}