import React, { FunctionComponent } from "react"
import styles from './app-bar.module.scss';
import { forceLogoff, getLoggedUser, openChangePasswordDialog } from "../../../store/authSlice";
import { DropdownItem, Dropdowns } from "../../utils/dropdowns";
import VpnKey from '../../../assets/images/icons/vpn_key.svg';
import Logout from '../../../assets/images/icons/logout.svg';
import Person from '../../../assets/images/icons/person.svg';
import MenuIcon from "../../../assets/images/icons/menu.svg";
import MopIcon from '../../../assets/images/icons/mop.svg';
import InfoIcon from '../../../assets/images/icons/info.svg';
import { Avatar } from "../../utils/avatar";
import { cleanLocalStorage, setAboutSectionIsOpened, toggleMenuIsOpen } from "../../../store/appSlice";
import { useAppDispatch } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SearchBar } from "../search-bar";

const UserAvatar: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loggedUser = useSelector(getLoggedUser)!;

    const mySelfItem: DropdownItem = {
        icon: Person,
        onClick: () => navigate('/my-self'),
        label: 'My Self',
    };

    const changePasswordItem: DropdownItem = {
        icon: VpnKey,
        onClick: () => dispatch(openChangePasswordDialog()),
        label: 'Edit Password',
    };

    const cleanAllCache: DropdownItem = {
        icon: MopIcon,
        onClick: () => dispatch(cleanLocalStorage()).then(() => navigate('./', {replace: true})),
        label: 'Clean Cache'
    }

    const about: DropdownItem = {
        icon: InfoIcon,
        onClick: () => dispatch(setAboutSectionIsOpened(true)),
        label: 'About'
    }

    const logoffItem: DropdownItem = {
        icon: Logout,
        onClick: () => dispatch(forceLogoff()),
        label: 'Exit',
    };

    return (
        <Dropdowns options={[mySelfItem, changePasswordItem, cleanAllCache, about, logoffItem]}>
            <Avatar primary={true} user={loggedUser}/>
        </Dropdowns>
    );
};


export const AppBar = () => {
    const dispatch = useAppDispatch();
    const toggleOpened = () => dispatch(toggleMenuIsOpen());

    return (
        <div className={styles.appBar}>
            <div className={styles.menuIcon} onClick={toggleOpened}>
                <img src={MenuIcon} alt="Menu icon"/>
            </div>
            <SearchBar/>
            <UserAvatar/>
        </div>
    );
}
