import { User } from "../types/User";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppThunkDispatcher, RootState } from "./index";
import { showAlert } from "./notificationSlice";
import UserService from "../services/UserService";
import { delay, retrieveFromLocalStorage, saveOnLocalStorage } from "../utils";
import { Websocket } from "../services/Websocket";

const MY_SELF_KEY = 'mySelfKey';

interface MySelfState {
    mySelf: User | null
}

const initialState: MySelfState = {
    mySelf: null
}

export const fetchMySelf = createAsyncThunk('user/mySelf', UserService.getMySelf);

const mySelfSlice = createSlice({
    name: 'mySelfSlice',
    initialState,
    reducers: {
        cleanupState: (state) => {
            state = initialState;
        },
        updateMySelf: (state, action: PayloadAction<User | null>) => {
            state.mySelf = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchMySelf.fulfilled, (state, action) => {
            state.mySelf = action.payload || {} as User;
        });
    },
});

const { cleanupState, updateMySelf } = mySelfSlice.actions;

export const cleanupMySelfState = (): AppThunk => dispatch => dispatch(cleanupState());

export const updateMySelfInfo = (user: User): AppThunk => dispatch =>
    UserService.updateMySelf(user)
        .then(() => {
            dispatch(showAlert('My self updated'));
        });

export const setupMySelfState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<User>('update-my-self', mySelf => {
        dispatch(updateMySelf(mySelf));
        return updateOnStorage(getState)
    })
}

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const mySelf = getState().mySelfState.mySelf;
    saveOnLocalStorage(MY_SELF_KEY, mySelf)
}

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const mySelf = retrieveFromLocalStorage<User>(MY_SELF_KEY);

    if(!mySelf)
        return;

    dispatch(updateMySelf(mySelf));
}

export const getMySelf = (state: RootState): User | null => state.mySelfState.mySelf;

export default mySelfSlice.reducer;
