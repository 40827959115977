import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppThunkDispatcher, RootState } from "./index";
import { delay, getPaged, retrieveFromLocalStorage, saveOnLocalStorage, upsertOnList } from "../utils";
import { Websocket } from "../services/Websocket";
import { BasePagination } from "../types";
import { showAlert } from "./notificationSlice";
import { Client, UpdateClientDto } from "../types/Client";
import ClientService from "../services/ClientService";

const CLIENTS_KEY = 'clients';
const CLIENTS_LAST_UPDATED_DATE_KEY = 'clients_last_updated';

interface ClientState {
    lstClients: Client[];
    selectedClientId: string | null;
}

const initialState: ClientState = {
    lstClients: [],
    selectedClientId: null
}

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        upsertClient: (state, action: PayloadAction<Client>) => {
            state.lstClients = upsertOnList(state.lstClients, action.payload);
        },
        updateClientList: (state, action: PayloadAction<Client[]>) => {
            state.lstClients = []
        },
        updateSelectedClientId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedClientId = action.payload || null;
        }
    }
});

export const { upsertClient, updateClientList, updateSelectedClientId } = clientSlice.actions;

export const updateClientInfo = (info: UpdateClientDto): AppThunk => dispatch =>
    ClientService.update(info)
        .then(() => {
            dispatch(showAlert('Client was updated'));
        });


export const setupClientState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);
    Websocket.onEvent<Client>('update-client', async cli => {
        console.log('update client event was received')
        dispatch(upsertClient(cli));

        const lastUpdatedDate = new Date().toISOString();
        await saveOnLocalStorage(CLIENTS_LAST_UPDATED_DATE_KEY, {lastUpdatedDate})

        return updateOnStorage(getState);
    });

    const { lastUpdatedDate } = retrieveFromLocalStorage(CLIENTS_LAST_UPDATED_DATE_KEY) || { lastUpdatedDate: new Date(0)};
    Websocket.emitOnConnection('list-clients', { lastUpdatedDate });
}

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const clients = getState().clientState.lstClients;
    saveOnLocalStorage(CLIENTS_KEY, clients);
}

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Client[]>(CLIENTS_KEY);

    if (!lst)
        return;

    lst.forEach(cli => dispatch(upsertClient(cli)));
}

export const cleanClientState = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(updateClientList([]));
    await updateOnStorage(getState)
}

export const getTotalClients = (state: RootState) => state.clientState.lstClients.length;
export const getClients = (page: BasePagination) => (state: RootState): Client[] => {
    const lst = state.clientState.lstClients;
    return getPaged(page, lst);
}
export const getClient = (id: string | undefined | null) => (state: RootState): Client | undefined =>
    state.clientState.lstClients.find(x => x.id === id);

export const getSelectedClient = (state: RootState): Client | undefined =>
    getClient(state.clientState.selectedClientId)(state);

export default clientSlice.reducer;