import { getUrlGenerator } from "../utils";
import { Api } from "./Api";
import { Bank } from "../types/Order";

const baseUrl = process.env.REACT_APP_ORDER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class OrderService {
    public static approveReceipt = (id: string, bank: Bank, externalId: string): Promise<void> =>
        Api.post(generateUrl('receipt', 'approve'), { id, bank, externalId });

    public static declineReceipt = (id: string, motive: string): Promise<void> =>
        Api.post(generateUrl('receipt', 'decline'), { id, motive });
}
