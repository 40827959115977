import React, { FunctionComponent } from "react";
import { TabOption, Tabs } from "../../../utils/tabs";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Card } from "../../../utils/card";
import { useSelector } from "react-redux";
import { getProfileIsOpen } from "../../../../store/appSlice";
import { ClientEdit } from "../client-edit";
import { ClientOrders } from '../client-orders'
import styles from './client.module.scss'
import { ClientAddress } from "../client-address";

export interface HasActiveProp {
    active: boolean;
}

type RouteOption = TabOption & {
    Component: FunctionComponent,
    path: string,
    Suffix?: FunctionComponent<HasActiveProp>
}
export const ClientDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const profileIsOpened = useSelector(getProfileIsOpen);

    const openRoute = (subRoute: string) => () => navigate(subRoute);
    const isActive = (subRoute: string) => location.pathname.includes(subRoute);

    const getBaseRoute = (subRoute: string) => ({
        onClick: openRoute(subRoute),
        active: isActive(subRoute),
        path: subRoute,
    });

    const options: RouteOption[] = [
        {
            ...getBaseRoute('order'),
            label: 'Orders',
            Component: ClientOrders
        },
        {
            ...getBaseRoute('edit'),
            label: 'Edit',
            Component: ClientEdit
        },
        {
            ...getBaseRoute('address'),
            label: 'Address',
            Component: ClientAddress
        }
    ];

    return (
        <Card
            className={`${styles.details} ${profileIsOpened ? styles.isProfileOpened : ''}`}
            classNameBody={styles.detailsBody}>
            <Tabs options={options}/>
            <Routes>
                {options.map(item =>
                    <Route key={item.path} path={item.path} element={<item.Component/>}/>
                )}

                <Route path={'*'} element={<Navigate to={options[0].path}/>}/>
            </Routes>
        </Card>
    )
}