import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Bank } from "../../../types/Order";
import SantanderLogo from '../../../assets/images/santander-logo.png';
import BBLogo from '../../../assets/images/bb-logo.png';
import BradescoLogo from '../../../assets/images/bradesco-logo.png';
import ItauLogo from '../../../assets/images/itau-logo.png';
import CEFLogo from '../../../assets/images/cef-logo.png';
import styles from './pick-bank.module.scss';

interface Props {
    value?: Bank
    onChange: (bank: Bank) => void;
}

interface BankInfo {
    bank: Bank;
    image: string;
}

const banks: BankInfo[] = [
    { bank: Bank.Santander, image: SantanderLogo },
    { bank: Bank.BB, image: BBLogo },
    { bank: Bank.Bradesco, image: BradescoLogo },
    { bank: Bank.Itau, image: ItauLogo },
    { bank: Bank.CEF, image: CEFLogo }
]

export const PickBank: FunctionComponent<Props> = props => {
    const [val, setVal] = useState<Bank>();

    useEffect(() => setVal(props.value), [props.value]);

    const propagateVal = useCallback((newVal: BankInfo) => {
        setVal(newVal.bank);
        props.onChange(newVal.bank);
    }, [setVal, props.onChange])

    return (
        <div className={styles.pickBank}>
            {banks.map(item => (
                <BankItem
                    active={item.bank === val}
                    onSelected={() => propagateVal(item)}
                    info={item}
                    key={item.bank}/>
            ))}
        </div>
    )
}

interface BankItemProp {
    active: boolean;
    onSelected: () => void;
    info: BankInfo;
}

const BankItem: FunctionComponent<BankItemProp> = props => {
    const style = props.active ? styles.active : '';

    return (
        <div className={`${style} ${styles.bankItem}`} onClick={props.onSelected}>
            <img src={props.info.image} alt={props.info.bank}/>
        </div>
    )
}