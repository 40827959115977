import React, { useState } from 'react';
import styles from './change-password.module.scss'
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks";
import {
    changePassword,
    closeChangePasswordDialog,
    getChangePasswordIsOpened,
    getPasswordMustBeChanged
} from "../../../store/authSlice";
import { ChangePasswordDto } from "../../../types/ChangePasswordDto";
import { Dialog } from "../../utils/dialog";
import { Field } from "../../utils/field";
import { Button } from "../../utils/button";

export const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const changePasswordIsOpened = useSelector(getChangePasswordIsOpened);
    const passwordMustBeChanged = useSelector(getPasswordMustBeChanged);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const isInvalidNewPassword = !!newPassword && !!currentPassword && newPassword === currentPassword;
    const isInvalidConfirmNewPassword = newPassword !== confirmNewPassword;

    const isUpdateDisabled = !currentPassword ||
        !newPassword ||
        !confirmNewPassword ||
        isInvalidNewPassword ||
        isInvalidConfirmNewPassword;

    const changePasswordForm: ChangePasswordDto = {
        currentPassword,
        newPassword,
        confirmNewPassword
    }

    const closeDialog = passwordMustBeChanged ? undefined : () =>
        dispatch(closeChangePasswordDialog());

    return (
        <Dialog
            isOpen={changePasswordIsOpened}
            onClose={closeDialog}
            title='Change Password'
            className={styles.changePassword}
        >
            <Field
                label="Current Password"
                placeholder="********"
                type="password"
                min={6}
                required={true}
                onChange={setCurrentPassword}
                value={currentPassword}
                className={styles.field}
            />
            <Field
                label="New Password"
                placeholder="********"
                type="password"
                min={6}
                required={true}
                onChange={setNewPassword}
                value={newPassword}
                className={styles.field}
            />
            {isInvalidNewPassword && (
                <span className={styles.error}>Current and new passwords must be different!</span>
            )}
            <Field
                label="Confirm New Password"
                placeholder="********"
                type="password"
                min={6}
                required={true}
                onChange={setConfirmNewPassword}
                value={confirmNewPassword}
                className={styles.field}
            />

            <div className={styles.actions}>
                {!!closeDialog && (
                    <Button
                        label="Cancel"
                        appearance="outline"
                        onClick={closeDialog}/>
                )}
                <Button
                    label='Update'
                    onClick={() => dispatch(changePassword(changePasswordForm))}
                    disabled={isUpdateDisabled}/>
            </div>
        </Dialog>
    )
}
