import { getUrlGenerator } from "../utils";
import axios from "axios";
import { Address } from "../types/Client";

const generateUrl = getUrlGenerator('https://viacep.com.br');

export class ViaCepService{
    public static get = (postalCode: string): Promise<Omit<Address, 'number' | 'latLng'>> =>
        axios.get(generateUrl('ws', postalCode, 'json'))
            .then(({ data }) => ({
                postalCode,
                street: data.logradouro,
                complement: data.complemento,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf,
            }))
}