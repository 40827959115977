import { HeaderTitle } from "../utils/header-title";
import styles from './clients.module.scss';
import { Card } from "../utils/card";
import { FunctionComponent, useState } from "react";
import { BasePagination, WithData } from "../../types";
import { basicPagination, formatDocument, phoneFormatter } from "../../utils";
import { getClients, getTotalClients } from "../../store/clientSlice";
import { useSelector } from "react-redux";
import { Paginator } from "../utils/paginator";
import { Client } from "../../types/Client";
import { useNavigate } from "react-router-dom";

export const Clients = () => (
    <Card className={styles.clients}>
        <HeaderTitle title='Clients'/>
        <ClientsTable/>
    </Card>
)

const ClientsTable = () => {
    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const lst = useSelector(getClients(pagination));
    const total = useSelector(getTotalClients);


    if (!total)
        return <div>No Client found</div>

    return (
        <div className={styles.tableClients}>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Document</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {lst.map(x => (
                    <ClientItem key={x.id} data={x}/>
                ))}
                </tbody>
            </table>
            <Paginator pagination={pagination} totals={total} setPage={setPagination}/>
        </div>
    )
}

const ClientItem: FunctionComponent<WithData<Client>> = ({data}) => {
    const navigate = useNavigate();
    const { name, companyInfo } = data;
    const completeName = !!companyInfo ? `${name} - ${companyInfo.name}` : name;

    const openClient = () => navigate(`/clients/${data.id}`);

    return (
        <tr>
            <td data-title='Name' onClick={openClient}>{completeName}</td>
            <td data-title='Phone' onClick={openClient}>{phoneFormatter(data.phoneNumber)}</td>
            <td data-title='Document' onClick={openClient}>{formatDocument(data.companyInfo?.document.number || data.document.number)}</td>
            <td data-title='Email' onClick={openClient}>{data.email}</td>
        </tr>
    )
}
