import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Settings, Settings as SettingsModel } from "../types/Settings";
import { AppThunk, AppThunkDispatcher, RootState } from "./index";
import { showAlert } from "./notificationSlice";
import SettingsService from "../services/SettingsService";
import { Websocket } from "../services/Websocket";
import { delay, retrieveFromLocalStorage, saveOnLocalStorage } from "../utils";

const SETTINGS_KEY = 'settings';

interface SettingsState {
    data: Settings | null;
}

const initialState: SettingsState = {
    data: null
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSettings: (state, action: PayloadAction<Settings | null>) => {
            state.data = action.payload;
        }
    }
});

const { updateSettings } = settingsSlice.actions;

export const putSettings = (obj: SettingsModel): AppThunk => dispatch => {
    SettingsService.updateSettings(obj)
        .then(x => dispatch(showAlert('Settings save')));
}

export const cleanSettingsState = (): AppThunk<Promise<void>> => (dispatch, getState) => {
    dispatch(updateSettings(null));
    return updateOnStorage(getState)
}

export const setupSettingsState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);
    Websocket.onEvent<Settings>('update-settings', async settings => {
        dispatch(updateSettings(settings));
        await updateOnStorage(getState);
    });
}

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const settings = getState().settingsState.data;
    saveOnLocalStorage(SETTINGS_KEY, settings);
}

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const settings = retrieveFromLocalStorage<Settings>(SETTINGS_KEY);

    if(!settings)
        return;

    dispatch(updateSettings(settings));
}

export const getSettings = (state: RootState): Settings | null => state.settingsState.data;

export default settingsSlice.reducer;



