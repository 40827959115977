import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Settings as SettingsModel } from '../../types/Settings';
import { Card } from "../utils/card";
import styles from './settings.module.scss';
import { useAppDispatch } from "../../hooks";
import { getSettings, putSettings } from "../../store/settingsSlice";
import { HeaderTitle } from "../utils/header-title";
import { Button } from "../utils/button";
import { FormFieldWithHint } from "../utils/form-field-with-hint";
import { Field } from "../utils/field";

export const Settings = () => {
    const dispatch = useAppDispatch();

    const [webhookUrl, setWebhookUrl] = useState<string | undefined>('');

    const settings = useSelector(getSettings);

    const updateForm = useCallback((): void => {
        if (!settings) return;

        setWebhookUrl(settings.webhookUrl);
    }, [settings]);

    useEffect(updateForm, [settings, updateForm]);

    const payload: SettingsModel = {
        webhookUrl
    };

    if (!settings)
        return (
            <Card className={styles.settings}>
                <HeaderTitle title='Settings'/>
                <div>loading...</div>
            </Card>
        )

    return (
        <Card className={styles.settings}>
            <HeaderTitle title="Settings">
                <Button
                    label="Discard changes"
                    className={styles.discardButton}
                    onClick={() => updateForm()}
                    appearance="outline"/>
                <Button label="Save changes" onClick={() => dispatch(putSettings(payload))} disabled={false}/>
            </HeaderTitle>

            <FormFieldWithHint label="Webhook Url" hint="This is the POST url will be called after a successfully purchase was paid">
                <Field
                    placeholder="https://api.rtk.com.br/purchase"
                    value={webhookUrl}
                    onChange={setWebhookUrl}/>
            </FormFieldWithHint>
        </Card>
    );
};