import React from "react";
import styles from './snack-bar.module.scss';
import { getLastSnack } from "../../../store/notificationSlice";
import { useSelector } from "react-redux";

export const SnackBar = () => {
    const snack = useSelector(getLastSnack);

    const showStyle = !!snack ? styles.show : '';
    return <div className={`${styles.snackBar} ${showStyle}`}>{snack}</div>
}
