import { useSelector } from "react-redux";
import { getSelectedClient, updateClientInfo } from "../../../../store/clientSlice";
import { useAppDispatch } from "../../../../hooks";
import styles from './client-address.module.scss'
import { useCallback, useEffect, useState } from "react";
import { Field } from "../../../utils/field";
import { ViaCepService } from "../../../../services/ViaCepService";
import { FloatingActionButton } from "../../../utils/floating-action-button";
import SaveIcon from "../../../../assets/images/icons/save.svg";
import { Address } from "../../../../types/Client";

export const ClientAddress = () => {
    const dispatch = useAppDispatch();
    const client = useSelector(getSelectedClient);

    const [loadingPostalCode, setLoadingPostalCode] = useState<boolean>(false);

    const [postalCode, setPostalCode] = useState<string>();
    const [street, setStreet] = useState<string>();
    const [number, setNumber] = useState<string>();
    const [neighborhood, setNeighborhood] = useState<string>();
    const [city, setCity] = useState<string>();
    const [state, setState] = useState<string>();
    const [complement, setComplement] = useState<string | undefined>();

    const updateAddress = useCallback(() => {
        const address: Address = {
            postalCode: postalCode!,
            street: street!,
            number: number!,
            neighborhood: neighborhood!,
            city: city!,
            state: state!,
            complement: complement
        };

        dispatch(updateClientInfo({id: client!.id, address}))
    }, [postalCode, street, number, neighborhood, city, state, complement]);

    useEffect(() => {
        if(!client)
            return;

        const {address} = client;

        setPostalCode(address.postalCode)
        setStreet(address.street)
        setNumber(address.number)
        setNeighborhood(address.neighborhood)
        setCity(address.city)
        setState(address.state)
        setComplement(address.complement)
    }, [client])

    useEffect(() => {
        if((postalCode?.length ?? 0) < 8 || postalCode === client?.address.postalCode)
            return;

        setLoadingPostalCode(true);
        ViaCepService.get(postalCode!)
            .then(item => {
                setStreet(item.street);
                setCity(item.city);
                setState(item.state);
                setNeighborhood(item.neighborhood);
            })
            .finally(() => setLoadingPostalCode(false));
    }, [postalCode]);

    if(!client)
        return <div>Loading...</div>;

    const invalidForm = !postalCode
        || !street
        || !number
        || !neighborhood
        || !city
        || !state;

    return (
        <div className={styles.clientAddress}>
            <Field
                label='Postal Code'
                placeholder="72001800"
                value={postalCode}
                onChange={setPostalCode}
            />
            <Field
                label='Street'
                placeholder='Col. Agr. Samambaia Chac.'
                value={street}
                onChange={setStreet}
                disabled={loadingPostalCode}
            />
            <Field
                label='Number'
                placeholder='128'
                value={number}
                onChange={setNumber}
            />
            <Field
                label='Complement'
                placeholder='Perto da casa do Seu Joel'
                value={complement}
                onChange={setComplement}
            />
            <Field
                label='Neighborhood'
                placeholder='Taguatinga Sul'
                value={neighborhood}
                onChange={setNeighborhood}
                disabled={loadingPostalCode}
            />
            <Field
                label='City'
                placeholder='Brasilia'
                value={city}
                onChange={setCity}
                disabled={loadingPostalCode}
            />
            <Field
                label='State'
                placeholder='DF'
                value={state}
                onChange={setState}
                disabled={loadingPostalCode}
            />

            <div>
                {!invalidForm && (
                    <FloatingActionButton icon={SaveIcon} onClick={updateAddress}/>
                )}
            </div>
        </div>
    )
}