import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { setupApplication, store } from "./store";
import { Provider } from "react-redux";

setupApplication()
    .then(() => console.log('The application was successfully setup'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <App/>
      </Provider>
  </React.StrictMode>
);

