import React from "react";
import { useSelector } from "react-redux";
import { getSelectedClient } from "../../../../store/clientSlice";
import { getOrdersByClient } from "../../../../store/orderSlice";
import { OrderTable } from "../../../utils/order-table";
import styles from './client-orders.module.scss';

export const ClientOrders = () => {
    const client = useSelector(getSelectedClient);
    const lst = useSelector(getOrdersByClient(client?.id));

    return (
        <div className={styles.clientOrders}>
            <OrderTable data={lst} emptyMessage='No order was found for this client'/>
        </div>
    )
}
