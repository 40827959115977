import { getUrlGenerator } from "../utils";
import { BasicUser, CreateUserDto, LoginRequest, LoginResponse, User } from "../types/User";
import { Api } from "./Api";
import { ChangePasswordDto } from "../types/ChangePasswordDto";

const baseUrl = process.env.REACT_APP_USER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class UserService {
    public static create2FA = (obj: LoginRequest): Promise<void> =>
        Api.post(generateUrl('backoffice', 'send-two-factor-code'), obj);

    public static login = (obj: LoginRequest): Promise<LoginResponse> =>
        Api.post<LoginResponse>(generateUrl('backoffice', 'login'), obj);

    public static renewToken = (): Promise<void> =>
        Api.get(generateUrl('renew-token'))

    public static changePassword = (payload: ChangePasswordDto): Promise<void> => {
        const obj = {
            currentPassword: payload.currentPassword,
            newPassword: payload.newPassword,
        }
        return Api.post(generateUrl('backoffice', 'change-password'), obj);
    };

    public static createOperator = (dto: CreateUserDto): Promise<User> =>
        Api.post(generateUrl('operator'), dto);

    public static updateOperator = (id: string, user: Partial<User>): Promise<User> =>
        Api.put(generateUrl('operator'), user);

    public static deleteOperator = (id: string): Promise<void> =>
        Api.delete(generateUrl('operator', id));

    public static resetOperatorPassword = (id: string): Promise<{ password: string }> =>
        Api.post(generateUrl('operator', 'reset-password', id));

    public static getMySelf = (): Promise<User> =>
        Api.get(generateUrl('backoffice', 'my-self'));

    public static updateMySelf = (user: User): Promise<User> =>
        Api.put(generateUrl('backoffice', 'my-self'), {
            name: user.name,
            phoneNumber: user.phoneNumber,
            email: user.email,
        });
}