import React, { FunctionComponent } from 'react';
import styles from './icon-button.module.scss'
import MoreIcon from '../../../assets/images/icons/more.svg';
import CancelIcon from '../../../assets/images/icons/cancel.svg';
import ArrowLeft from '../../../assets/images/icons/arrow-left.svg';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg'

type iconTypes =
    'more' |
    'cancel' |
    'arrow-left' |
    'arrow-right';

type styleTypes = 'default' | 'slim';
type sizeTypes = 'normal' | 'big';

interface Props {
    icon: iconTypes;
    onClick: Function;
    styleWeight?: styleTypes;
    size?: sizeTypes;
    disabled?: boolean;
    className?: string;
}

const iconOptions: { icon: iconTypes; image: any }[] = [
    { icon: 'more', image: MoreIcon },
    { icon: 'cancel', image: CancelIcon },
    { icon: 'arrow-left', image: ArrowLeft },
    { icon: 'arrow-right', image: ArrowRight }
];

const styleOptions = new Map<styleTypes, string>();
styleOptions.set('default', styles.default);
styleOptions.set('slim', styles.slim);

const sizeOptions = new Map<sizeTypes, string>();
sizeOptions.set('normal', styles.normal);
sizeOptions.set('big', styles.big);

export const IconButton: FunctionComponent<Props> = props => {
    const { image } = iconOptions.find(x => x.icon === props.icon)!;
    const style = styleOptions.get(props.styleWeight || 'default');
    const size = sizeOptions.get(props.size || 'normal');
    const disabledStyle = props.disabled ? styles.disabled : '';

    const handleClick = () => {
        if (props.disabled) return;

        props.onClick();
    };

    return (
        <div
            className={`${styles.iconButton} ${style} ${size} ${disabledStyle} ${props.className}`}
            onClick={handleClick}>
            <img src={image} alt={props.icon}/>
        </div>
    );
};
