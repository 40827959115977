import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { showAlert } from './notificationSlice';
import { CreateUserDto, UpdateUserDto, User } from '../types/User';
import UserService from '../services/UserService';
import { delay, retrieveFromLocalStorage, saveOnLocalStorage, upsertOnList } from "../utils";
import { Websocket } from "../services/Websocket";

const OPERATORS_KEY = 'operators';

interface OperatorState {
    operators: User[];
}

const initialState: OperatorState = {
    operators: []
};

const operatorSlice = createSlice({
    name: 'operatorSlice',
    initialState,
    reducers: {
        upsertOperator: (state, action: PayloadAction<User>) => {
            state.operators = upsertOnList(state.operators, action.payload);
        },
        updateOperatorList: (state, action: PayloadAction<User[] | undefined>) => {
            state.operators = action.payload || [];
        },
        removeOperator: (state, action: PayloadAction<string>) => {
            state.operators = state.operators.filter(x => x.id !== action.payload);
        }
    },
});

export const {
    upsertOperator,
    updateOperatorList,
    removeOperator
} = operatorSlice.actions;

export const createOperator = (operator: CreateUserDto): AppThunk<Promise<void>> => dispatch =>
    UserService.createOperator(operator)
        .then(_operator => dispatch(showAlert('Operator created')));

export const updateOperator = (operator: UpdateUserDto): AppThunk<Promise<void>> => dispatch =>
    UserService.updateOperator(operator.id, operator)
        .then((obj) => dispatch(showAlert('Operator updated')));

export const deleteOperator = (id: string): AppThunk<Promise<void>> => dispatch =>
    UserService.deleteOperator(id)
        .then(() => dispatch(showAlert('Operator deleted')));

export const resetOperatorPassword = (id: string): AppThunk => dispatch =>
    UserService.resetOperatorPassword(id)
        .then(() => dispatch(showAlert('Password was reset')));

export const setupOperatorState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);
    Websocket.onEvent<User[]>('list-all-operators', lst => {
        dispatch(updateOperatorList(lst));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<User>('update-operator', async user => {
        dispatch(upsertOperator(user));
        await updateOnStorage(getState);
    });

    Websocket.onEvent<User>('deleted-operator', async user => {
        dispatch(removeOperator(user.id));
        await updateOnStorage(getState);
    })
}

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const operators = getState().operatorState.operators;
    saveOnLocalStorage(OPERATORS_KEY, operators);
}

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<User[]>(OPERATORS_KEY);

    if(!lst)
        return;

    dispatch(updateOperatorList(lst));
}

export const cleanOperatorState = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(updateOperatorList([]));
    await updateOnStorage(getState);
}

export const getOperators = (state: RootState): User[] => state.operatorState.operators;
export const getOperator = (id?: string) => (state: RootState): User|undefined =>
    state.operatorState.operators.find(x => x.id === id);

export default operatorSlice.reducer;
