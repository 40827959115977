import { BasicClient, BasicKnownUser } from "./index";

export enum Bank{
    BB = 'Banco do Brasil',
    Santander = 'Santander',
    Bradesco = 'Bradesco',
    Itau = 'Itau',
    CEF = 'Caixa economica federal'
}

export enum PaymentType{
    pix = 'pix',
    ted = 'ted'
}

export interface BasePaidPayment {
    externalId: string;
    statusDate: Date;
    type: PaymentType;
    bank: Bank;
}

export interface PixPayment extends BasePaidPayment{
    type: PaymentType.pix;
}

export interface ManualApprovedPayment extends BasePaidPayment{
    type: PaymentType.ted;
    operator: BasicKnownUser;
    receiptId: string;
}

export interface ManualDeclinedPayment {
    type: PaymentType.ted;
    operator: BasicKnownUser;
    receiptId: string;
    statusDate: Date;
    motive: string;
}

export enum OrderStatus{
    created = 'created',
    paid = 'paid',
    pending = 'pending',
    declined = 'declined'
}


export interface BaseOrder {
    id: string;
    chargeId: string;
    address: string;
    amount: number;
    partnerId?: string;
    pixQrCode: {
        id: string;
        payload: string
    },
    client: BasicClient;
    status: OrderStatus;
    updatedAt: Date;
    createdAt: Date;
}

export interface CreatedOrder extends BaseOrder{
    status: OrderStatus.created;
}

export interface DeclinedOrder extends BaseOrder{
    id: string;
    status: OrderStatus.declined;
    payment: ManualDeclinedPayment;
}

export interface PendingOrder extends BaseOrder{
    id: string;
    status: OrderStatus.pending;
    payment: {
        type: PaymentType.ted,
        receiptId: string;
    }
}

export interface BasePaidOrder extends BaseOrder {
    id: string;
    status: OrderStatus.paid;
    payment: PixPayment | ManualApprovedPayment;
}

export interface PaidManuallyApprovedOrder extends BasePaidOrder {
    payment: ManualApprovedPayment;
}

export interface PaidPixOrder extends BasePaidOrder {
    payment: PixPayment;
}

export type PaidOrder = PaidManuallyApprovedOrder | PaidPixOrder;
export type Order = CreatedOrder | DeclinedOrder | PendingOrder | PaidOrder;
export type OrderWithTedAsPayment =  DeclinedOrder | PendingOrder | PaidManuallyApprovedOrder;