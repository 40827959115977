import React, { useEffect, useState } from 'react';
import styles from './search-bar.module.scss';
import SearchIcon from '../../../assets/images/icons/search.svg';
import MallIcon from '../../../assets/images/icons/local-mall.svg';
import { IconButton } from '../../utils/icon-button';

import Person from '../../../assets/images/icons/person.svg';
import { formatPriceAmount, phoneFormatter } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";
import { getClientsMatchTerm, getHasSearchTerm, getOrderMatchTerm, updateSearchTerm } from "../../../store/searchSlice";
import { selectOrderId } from "../../../store/orderSlice";

export const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const lstClients = useSelector(getClientsMatchTerm);
    const lstOrders  = useSelector(getOrderMatchTerm);
    const hasSearchTerm = useSelector(getHasSearchTerm);

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            dispatch(updateSearchTerm(searchTerm));
        }, 500);

        return () => {
            clearTimeout(typingTimer);
        };
    }, [searchTerm, dispatch]);

    const clearTerm = () => {
        setSearchTerm('');
    };

    const openClient = (id: string) => {
        clearTerm();
        navigate(`/clients/${id}`);
    };

    const openOrder = (id: string) => {
        clearTerm();
        dispatch(selectOrderId(id));
    }

    const hasResultSearch = !!lstClients.length || !!lstOrders.length;
    const displayResult = hasResultSearch ? styles.hasResult : styles.noResult;

    return (
        <div className={styles.searchBar}>
            <img src={SearchIcon} alt="Search icon" />
            <input type="text" value={searchTerm} onChange={el => setSearchTerm(el.target.value)} />

            <div className={styles.icon}>
                {!!searchTerm && (
                    <IconButton icon="cancel" size="normal" styleWeight="slim" onClick={clearTerm} />
                )}
            </div>

            {hasSearchTerm && (
                <div className={`${styles.componentSearch} ${displayResult}`}>
                    {!hasResultSearch && <span>No result found</span>}
                    {lstClients.map(x => (
                        <div className={styles.resultSearchItem}>
                            <img src={Person} alt="" />
                            <div className={styles.resultInfo} onClick={() => openClient(x.id)}>
                                <div>{x.name}</div>
                                <div>
                                    {phoneFormatter(x.phoneNumber)} - {x.email}
                                </div>
                            </div>
                        </div>
                    ))}
                    {lstOrders.map(x => (
                        <div className={styles.resultSearchItem}>
                            <img src={MallIcon} alt=""/>

                            <div className={styles.resultInfo} onClick={() => openOrder(x.id)}>
                                <div>{x.address}</div>
                                <div>
                                    {formatPriceAmount(x.amount)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

//0xb794f5ea0ba39494ce839613fffba74279579268