import { ConfirmationParams } from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./index";
import { Api } from "../services/Api";
import { logoff } from "./authSlice";

interface AppState {
    confirm: ConfirmationParams | null;
    menuIsOpened: boolean;
    hasConnection: boolean;
    aboutSectionIsOpened: boolean;
    profileIsOpened: boolean;
}

const initialState: AppState = {
    confirm: null,
    menuIsOpened: false,
    hasConnection: false,
    aboutSectionIsOpened: false,
    profileIsOpened: false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        openConfirmation: (state, action: PayloadAction<ConfirmationParams>) => {
            state.confirm = action.payload;
        },
        closeConfirmation: state => {
            state.confirm = null;
        },
        toggleMenuIsOpen: state => {
            state.menuIsOpened = !state.menuIsOpened;
        },
        setAboutSectionIsOpened: (state, action: PayloadAction<boolean>) => {
            state.aboutSectionIsOpened = action.payload;
        },
        setHasConnection: (state, action: PayloadAction<boolean>) => {
            state.hasConnection = action.payload;
        },
        toggleProfileOpen: state => {
            state.profileIsOpened = !state.profileIsOpened;
        }
    }
});

export const {
    openConfirmation,
    closeConfirmation,
    toggleMenuIsOpen,
    setHasConnection,
    setAboutSectionIsOpened
} = appSlice.actions;

export const getConfirmation = (state: RootState): ConfirmationParams | null => state.appState.confirm;
export const getMenuIsOpen = (state: RootState): boolean => state.appState.menuIsOpened;
export const getAboutSectionIsOpened = (state: RootState): boolean => state.appState.aboutSectionIsOpened;
export const getProfileIsOpen = (state: RootState): boolean => state.appState.profileIsOpened;


export const setupAppState = (): AppThunk => async dispatch => {
    const verifyHasConnection = () =>
        Api.hasConnection()
            .then(conn => dispatch(setHasConnection(conn)));

    verifyHasConnection()
        .then(() => setInterval(verifyHasConnection, 5 * 1000))
};

export const cleanLocalStorage = (): AppThunk<Promise<void>> => async dispatch => {

    dispatch(logoff());
}

export default appSlice.reducer;