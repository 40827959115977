import React, { useEffect } from "react";

const useKeypress = (key: string, action: Function, deps: React.DependencyList) => {
    useEffect(() => {
        const onKeyup = (e: any) => {
            if (e.key === key)
                action();
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [deps, action,key]);
}


export default useKeypress;