import React, { FunctionComponent, useCallback, useState } from "react";
import { WithData } from "../../../types";
import { PendingOrder } from "../../../types/Order";
import { Field } from "../../utils/field";
import styles from './reject-order-receipt.module.scss'
import { Button } from "../../utils/button";
import { useAppDispatch } from "../../../hooks";
import { cleanNextStatusOrder, NextStatusOrder, rejectReceipt } from "../../../store/orderSlice";

export const RejectOrderReceipt: FunctionComponent<WithData<PendingOrder>> = ({data}) => {
    const dispatch = useAppDispatch();
    const [motive, setMotive] = useState('');

    const goBack = useCallback(() => {
        dispatch(cleanNextStatusOrder())
    }, [])

    const decline = useCallback(() => {

        dispatch(rejectReceipt(motive))
    }, [motive])

    return (
        <div className={styles.rejectOrderReceipt}>

            <Field
                label='Motive'
                type='textArea'
                placeholder='The price was incorrect'
                value={motive}
                onChange={setMotive}/>

            <div className={styles.actions}>
                <Button
                    label='Back'
                    color='warning'
                    appearance='outline'
                    onClick={goBack}/>
                <Button
                    label='Reject'
                    color='error'
                    disabled={!motive}
                    onClick={decline}/>
            </div>
        </div>
    )
}