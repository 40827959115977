import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Bank, Order, OrderStatus } from "../types/Order";
import { delay, getPaged, retrieveFromLocalStorage, saveOnLocalStorage, upsertOnList } from "../utils";
import { AppThunk, AppThunkDispatcher, RootState } from "./index";
import { Websocket } from "../services/Websocket";
import OrderService from "../services/OrderService";
import { BasePagination } from "../types";

const ORDERS_KEY = 'orders';
const ORDERS_LAST_UPDATED_DATE_KEY = 'orders_last_updated';

export enum NextStatusOrder {
    WillDecline = 1,
    WillApprove = 2
}

interface OrderState {
    lstOrders: Order[];
    selectedId: string | null;
    selectedOrderApprovalStatus: NextStatusOrder | null;
}

const initialState: OrderState = {
    lstOrders: [],
    selectedId: null,
    selectedOrderApprovalStatus: null
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        selectOrderId: (state, action: PayloadAction<string>) => {
            state.selectedId = action.payload
        },
        cleanSelectedOrder: state => {
            state.selectedId = null;
            state.selectedOrderApprovalStatus = null;
        },
        setNextStatusSelectedOrder: (state, action: PayloadAction<NextStatusOrder>) => {
            state.selectedOrderApprovalStatus = action.payload;
        },
        cleanNextStatusOrder: state => {
            state.selectedOrderApprovalStatus = null;
        },
        upsertOrder: (state, action: PayloadAction<Order>) => {
            state.lstOrders = upsertOnList(state.lstOrders, action.payload);
        },
    }
});

const {upsertOrder} = orderSlice.actions;
export const {selectOrderId, cleanSelectedOrder, setNextStatusSelectedOrder, cleanNextStatusOrder} = orderSlice.actions;

export const approveReceipt = (externalId: string, bank: Bank): AppThunk => (dispatch, getState) => {
    const orderId = getState().orderState.selectedId!;

    OrderService.approveReceipt(orderId, bank, externalId)
        .then(() => dispatch(cleanSelectedOrder()));
}

export const rejectReceipt = (motive: string): AppThunk => (dispatch, getState) => {
    const orderId = getState().orderState.selectedId!;

    OrderService.declineReceipt(orderId, motive)
        .then(() => dispatch(cleanSelectedOrder()));
}


export const setupOrderState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);
    Websocket.onEvent<Order>('update-order', async order => {
        dispatch(upsertOrder(order));

        const lastUpdatedDate = new Date().toISOString();
        await saveOnLocalStorage(ORDERS_LAST_UPDATED_DATE_KEY, {lastUpdatedDate})

        return updateOnStorage(getState);
    });

    const {lastUpdatedDate} = retrieveFromLocalStorage(ORDERS_LAST_UPDATED_DATE_KEY) || {lastUpdatedDate: new Date(0)};
    Websocket.emitOnConnection('list-orders', {lastUpdatedDate});
}

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const orders = getState().orderState.lstOrders;
    saveOnLocalStorage(ORDERS_KEY, orders);
}

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Order[]>(ORDERS_KEY);

    if (!lst)
        return;

    lst.forEach(order => dispatch(upsertOrder(order)))
}

const sortByImportanceAndDate = (item1: Order, item2: Order): 1 | -1 => {
    if (item1.status === OrderStatus.pending)
        return -1;

    if (item2.status === OrderStatus.pending)
        return 1;

    return item1.createdAt.getTime() > item2.createdAt.getTime() ? -1 : 1;
}

export const getOrdersByClient = (clientId: string | undefined) => (state: RootState): Order[] => {
    if (!clientId)
        return [];

    return state.orderState.lstOrders.filter(x => x.client.id === clientId).sort(sortByImportanceAndDate);
}

export const getOrders = (page: BasePagination) => (state: RootState): Order[] => {
    const lst = state.orderState.lstOrders.filter(x => !!x).sort(sortByImportanceAndDate);
    return getPaged(page, lst);
}

export const getTotalOrders = (state: RootState): number => state.orderState.lstOrders.length;

export const getSelectedOrder = (state: RootState): Order | undefined => state.orderState.lstOrders.find(x => x.id === state.orderState.selectedId);
export const getNextStatusSelectedOrder = (state: RootState): NextStatusOrder | null => state.orderState.selectedOrderApprovalStatus;

export default orderSlice.reducer;
