import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../hooks";
import { Card } from "../../../utils/card";
import { Button } from "../../../utils/button";
import { BaseFormUser } from "../../base-form-user";
import { CreateUserDto, UpdateUserDto } from "../../../../types/User";
import { deleteOperator, getOperator, updateOperator } from "../../../../store/operatorSlice";
import { HeaderTitle } from "../../../utils/header-title";
import { openConfirmation } from "../../../../store/appSlice";

export const UpdateOperator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const currentOperator = useSelector(getOperator(id));

    const goToOperators = useCallback(() => navigate('/operators'), [navigate]);

    const handleUpdate = useCallback((obj: CreateUserDto | UpdateUserDto) => {
        dispatch(updateOperator(obj as UpdateUserDto))
            .then(goToOperators)
    }, [goToOperators, dispatch]);

    const firstName = currentOperator?.name.split(' ')[0];
    const handleDeletion = useCallback(() =>
        dispatch(openConfirmation({
            title: `Remove ${firstName}`,
            text: 'Are you sure you want delete the operator',
            onConfirm: () => dispatch(deleteOperator(currentOperator!.id)).then(goToOperators)
        })), [goToOperators]);

    if (!currentOperator)
        return <div>loading...</div>;

    return (
        <Card>
            <HeaderTitle title={`Edit ${firstName}`}>
                <Button
                    label="Delete"
                    appearance="outline"
                    color='error'
                    onClick={handleDeletion}/>
            </HeaderTitle>
            <BaseFormUser
                value={currentOperator}
                labelAction='Update'
                onAction={handleUpdate}
            />
        </Card>
    );
};

