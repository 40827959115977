export enum RoleEnum {
    admin = 1,
    operator = 2,
    client = 3,
    partner = 4
}

export interface LoginRequest {
    email: string;
    password: string;
    twoFactorCode?: string;
}

export interface LoginResponse {
    token: string;
    passwordMustBeChanged: boolean;
}

export interface BasicUser{
    name: string;
    phoneNumber: string;
    email: string;
}

export interface User extends BasicUser{
    id: string;
    role: RoleEnum
}

export type CreateUserDto = BasicUser;
export interface UpdateUserDto extends BasicUser{
    id: string;
}