import React from 'react';
import { RoleEnum } from "./types/User";
import SettingsIcon from './assets/images/icons/settings.svg';
import ManageAccountsIcon from './assets/images/icons/manage_accounts.svg';
import ClientsIcon from './assets/images/icons/people.svg';
import OrderIcon from   './assets/images/icons/orders.svg'
import { Settings } from "./components/settings";
import { useSelector } from "react-redux";
import { getLoggedUser } from "./store/authSlice";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import styles from './app.module.scss';
import { About } from "./components/app/about";
import { ChangePassword } from "./components/app/change-password";
import { Confirmation } from "./components/app/confirmation";
import { RenewTokenHelper } from "./components/app/renew-token-helper";
import { Alerts } from "./components/app/alerts";
import { SnackBar } from "./components/app/snack-bar";
import { Login } from "./components/app/login";
import { Menu } from "./components/app/menu";
import { AppBar } from "./components/app/app-bar";
import { Operators } from "./components/user/operators";
import { UpdateOperator } from "./components/user/operators/update-operator";
import { AddOperator } from "./components/user/operators/add-operator";
import { MySelf } from "./components/user/my-self";
import { Client } from "./components/clients/client";
import { Clients } from "./components/clients";
import { OrderDetail } from "./components/order-detail";
import { Orders } from "./components/orders";

export interface IRoute {
    path: string;
    Component: () => JSX.Element;
    authorized: RoleEnum[];
}

export interface MenuItem extends IRoute {
    icon: any;
    label: string;
}

const routesWithMenu: MenuItem[] = [
    {
        icon: OrderIcon,
        label: 'Orders',
        path: '/orders',
        Component: Orders,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        icon: ClientsIcon,
        label: 'Clients',
        path: '/clients',
        Component: Clients,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        icon: ManageAccountsIcon,
        label: 'Operators',
        path: '/operators',
        Component: Operators,
        authorized: [RoleEnum.admin],
    },
    {
        icon: SettingsIcon,
        label: 'Settings',
        path: '/settings',
        Component: Settings,
        authorized: [RoleEnum.admin],
    },
];

const routesWithoutMenu: IRoute[] = [
    {
        path: '/operators/add',
        Component: AddOperator,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/operators/:id',
        Component: UpdateOperator,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/my-self',
        Component: MySelf,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        path: '/clients/:id/*',
        Component: Client,
        authorized: [RoleEnum.admin, RoleEnum.operator]
    },
];


const routes = [...routesWithoutMenu, ...routesWithMenu];

function App() {
    const loggedUser = useSelector(getLoggedUser);

    if (!loggedUser)
        return <Login/>

    return (
        <>
            <SnackBar/>
            <Alerts/>
            <RenewTokenHelper/>
            <Confirmation/>
            <ChangePassword/>
            <About/>
            <BrowserRouter>
                <Routes>
                    <Route element={
                        <div className={styles.app}>
                            <Menu routes={routesWithMenu}/>
                            <div className={styles.content}>
                                <AppBar/>
                                <Outlet />
                            </div>
                        </div>
                    }>
                        {routes.map(route => (
                            <Route path={route.path} key={route.path} element={<route.Component/>} />
                        ))}
                    </Route>
                    <Route path="*" element={<Navigate to={routesWithMenu[0].path} />} />
                </Routes>
            </BrowserRouter>
            <OrderDetail />
        </>
    )
}

export default App;
