import { RoleEnum, User } from "../types/User";
import { BasePagination, HasId } from "../types";

export const isValidDateString = (value: any): boolean =>
    /^(\d{4})-(\d{2})-(\d{2})([T\s](\d{2}):(\d{2}):(\d{2})(\.(\d+)(Z)?)?)?$/.test(value);

export const delay = (time: number): Promise<void> =>
    new Promise<void>(resolve => setTimeout(resolve, time));

export function apiResponseFormatter<T>(obj: T): T {
    if (!obj) return obj;

    if (Array.isArray(obj)) {
        return obj.map(i => apiResponseFormatter(i)) as any;
    }

    if (typeof obj === 'string' && isValidDateString(obj)) {
        return new Date(obj) as any;
    }

    if (typeof obj === 'object' && !(obj instanceof Date)) {
        return Object.keys(obj).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = apiResponseFormatter(obj[key]);
            return acc;
        }, {}) as T;
    }

    return obj;
}

export const getUrlGenerator = (baseUrl: string | undefined) => (...args: (string | number | object)[]) => {
    const params = args
        .map(x => {
            if (typeof x === 'object') {
                const urlParams = new URLSearchParams(x as Record<any, any>);

                const query = Array.from(urlParams.keys())
                    .filter(x => urlParams.get(x) !== 'undefined')
                    .map(x => `${x}=${urlParams.get(x)}`)
                    .join('&');

                return `?${query}`;
            }

            return x;
        })
        .join('/');

    return `${baseUrl}/${params}`;
};

export const generateUuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const userFromToken = (token: string): User | undefined => {
    if(!token)
        return;

    const payloadToken = token.split('.')[1];

    if(!payloadToken)
        return;

    const decoded = window.atob(payloadToken);
    return JSON.parse(decoded);
}

export const saveOnLocalStorage = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
}
export const retrieveFromLocalStorage = <T>(key: string): T | undefined => {
    const stringPayload = localStorage.getItem(key);

    if(!stringPayload)
        return;

    const result: T = JSON.parse(stringPayload);
    return apiResponseFormatter(result);
}

export const isAuthorized = (user: User, authorizedRoles: RoleEnum[]): boolean =>
    authorizedRoles.some(x => x === user.role);

export const getPaged = <T>(page: BasePagination, lst: T[]): T[] => {
    if(!lst.length)
        return [];

    const { length } = lst;
    const start = page.pageSize * page.pageIndex;
    const end = page.pageSize * (page.pageIndex + 1);

    return lst.slice(Math.min(start, length), Math.min(end, length));
}

export const upsertOnList = <T extends HasId>(lst: T[], obj: T): T[] => {
    const position = lst.map(x => x.id).indexOf(obj.id);

    if (position === -1)
        return [...lst, obj];

    const newList = lst.filter(x => x.id !== obj.id);
    newList.splice(position, 0, obj);

    return newList;
};

export const phoneFormatter = (phoneNumber: string): string => {
    const num = phoneNumber.replace(/^55/, '');
    return `(${num.slice(0, 2)}) ${num.slice(2, 3)} ${num.slice(3, 7)}-${num.slice(-4)}`;
};

export const isEmailValid = (email: string) => !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const userIsEqual = (newObj: Partial<User>|null|undefined, oldObj: Partial<User>|undefined|null): boolean => {
    if (!oldObj || !newObj)
        return false;

    const isEqual = newObj.id === oldObj.id &&
        newObj.name === oldObj.name &&
        newObj.phoneNumber === oldObj.phoneNumber &&
        newObj.email === oldObj.email;

    if (!oldObj.role || !newObj.role)
        return isEqual;

    return isEqual && newObj.role === oldObj.role;
}

export const basicPagination: BasePagination = {
    pageIndex: 0,
    pageSize: 10,
};

export const cpfFormatter = (cpf: string | null | undefined): string => {
    if (!cpf)
        return '';

    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
};

export const cnpjFormatter = (cnpj: string | null | undefined): string => {
    if (!cnpj)
        return '';

    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(-2)}`;
};

export const formatDocument = (document: string) =>
    document.length === 14 ? cnpjFormatter(document) : cpfFormatter(document);

export const formatPriceAmount = (n: number): string => {
    const format = new Intl.NumberFormat();
    return `R$ ${format.format(n)}`;
}