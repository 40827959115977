import React, { useEffect, useState } from 'react';
import styles from './choose-options.module.scss';
import { Option } from "../../../types";

export type ChooseOptions<T> = Option<T>[];

interface Prop<T> {
    label: string;
    value: T;
    options: ChooseOptions<T>;
    onChange: (item: T) => void;
}

export const ChooseOption = <T extends {}>(props: Prop<T>) => {
    const [val, setVal] = useState<T>();

    useEffect(() => {
        setVal(props.value);
    }, [props.value]);

    const renderItem = (option: Option<T>, index: number) => {
        const isActive = option.value === val;
        const className = `${styles.optionItem} ${isActive ? styles.active : ''}`;
        return (
            <div className={className} key={index} onClick={() => props.onChange(option.value)}>
                <input type="radio" checked={isActive} onChange={() => props.onChange(option.value)}/>
                <span>{option.label}</span>
            </div>
        );
    };

    return (
        <div className={styles.chooseOptions}>
            <span className={styles.title}>{props.label}</span>
            <div className={styles.options}>
                {props.options.map(renderItem)}
            </div>
        </div>
    )
}
