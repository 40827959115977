import { Field } from "../../../utils/field";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedClient, updateClientInfo } from "../../../../store/clientSlice";
import { FloatingActionButton } from "../../../utils/floating-action-button";
import SaveIcon from '../../../../assets/images/icons/save.svg'
import { useAppDispatch } from "../../../../hooks";
import { ClientType, Status, UpdateClientDto } from "../../../../types/Client";
import styles from './client-edit.module.scss';
import { SelectField, SelectFieldOptions } from "../../../utils/select-field";
import { Switch } from "../../../utils/switch";
import { ChooseOption, ChooseOptions } from "../../../utils/choose-option";

const statusOptions: SelectFieldOptions<Status> = [
    {
        label: 'Active',
        value: Status.active
    },
    {
        label: 'Frozen',
        value: Status.frozen
    },
    {
        label: 'Pending Approval',
        value: Status.pendingApproval
    }
];

const clientTypeOptions: ChooseOptions<ClientType> = [
    {
        label: 'Business',
        value: ClientType.business
    },
    {
        label: 'Individual',
        value: ClientType.individual
    }
]

export const ClientEdit = () => {
    const dispatch = useAppDispatch();

    const client = useSelector(getSelectedClient)!;

    const [clientType, setClientType] = useState<ClientType>();
    const [name, setName] = useState<string>();
    const [document, setDocument] = useState<string>();
    const [status, setStatus] = useState<Status>();
    const [email, setEmail] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [receiveEmails, setReceiveEmails] = useState<boolean>();
    const [companyName, setCompanyName] = useState<string | undefined>();
    const [companyDocument, setCompanyDocument] = useState<string | undefined>();
    const [birthOrStartDate, setBirthOrStartDate] = useState<Date>();

    useEffect(() => {
        if (!client)
            return;

        setClientType(client.clientType);
        setName(client.name);
        setDocument(client.document.number);
        setCompanyName(client.companyInfo?.name);
        setCompanyDocument(client.companyInfo?.document.number);
        setStatus(client.status);
        setEmail(client.email);
        setPhoneNumber(client.phoneNumber);
        setReceiveEmails(client.receiveEmails);
        setBirthOrStartDate(client.birthOrStartDate);
    }, [client]);

    const isCompany = clientType === ClientType.business;

    const updateClient = () => {
        const payload: UpdateClientDto = {
            id: client!.id,
            name,
            document: {
                ...client.document,
                number: document!,
            },
            status: status,
            email,
            phoneNumber,
            companyInfo: !isCompany ? undefined : {
                name: companyName!,
                document: {
                    ...client.companyInfo!.document,
                    number: companyDocument!,
                }
            },
        }

        dispatch(updateClientInfo(payload));
    };

    const invalidForm =
        !name ||
        !document ||
        (isCompany && !companyName) ||
        !phoneNumber ||
        !email ||
        receiveEmails === undefined ||
        !birthOrStartDate;

    return (
        <div className={styles.clientEdit}>
            <ChooseOption
                label='Client Type'
                value={clientType!}
                options={clientTypeOptions}
                onChange={setClientType}
            />
            <Field
                placeholder="Jhon"
                type="text"
                label='Name'
                value={name}
                onChange={setName}
            />
            <Field
                placeholder="03297258000190"
                type="text"
                label='Document'
                value={document}
                onChange={setDocument}
            />
            {isCompany && (
                <>
                    <Field
                        label='Company Name'
                        placeholder='Pesqueiro Sao Lucas'
                        value={companyName}
                        onChange={setCompanyName}
                        type='text'
                    />
                    <Field
                        label='Company Document'
                        placeholder='37503629000164'
                        value={companyDocument}
                        onChange={setCompanyDocument}
                    />
                </>
            )}
            <Field
                label='Phone Number'
                placeholder='5561992847385'
                value={phoneNumber}
                onChange={setPhoneNumber}
                type='text'
            />
            <Field
                label='Email'
                placeholder='jhondoe@gmail.com'
                value={email}
                onChange={setEmail}
                type='email'
            />
            <SelectField
                label='Status'
                options={statusOptions}
                onChange={setStatus}
                value={status}
            />
            <Switch value={receiveEmails!} onChange={setReceiveEmails} className={styles.toggle}>
                Notification by email
            </Switch>
            <div>
                {!invalidForm && (
                    <FloatingActionButton icon={SaveIcon} onClick={updateClient}/>
                )}
            </div>
        </div>
    )
}