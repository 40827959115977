import { Dialog } from "../../utils/dialog";
import { getAboutSectionIsOpened, setAboutSectionIsOpened } from "../../../store/appSlice";
import { useSelector } from "react-redux";
import styles from './about.module.scss';
import { useAppDispatch } from "../../../hooks";

export const About = () => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getAboutSectionIsOpened);

    return (
        <Dialog
            isOpen={isOpen}
            title='About'
            className={styles.about}
            onClose={() => dispatch(setAboutSectionIsOpened(false))}
        >
            <h3>Version</h3>
            <h6>{process.env.REACT_APP_VERSION}</h6>
        </Dialog>
    )
}