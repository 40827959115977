import React, { useState } from "react";
import { Card } from "../utils/card";
import styles from './orders.module.scss'
import { HeaderTitle } from "../utils/header-title";
import { OrderTable } from "../utils/order-table";
import { useSelector } from "react-redux";
import { getOrders, getTotalOrders } from "../../store/orderSlice";
import { Paginator } from "../utils/paginator";
import { BasePagination } from "../../types";
import { basicPagination } from "../../utils";

export const Orders = () => {
    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const orders = useSelector(getOrders(pagination));
    const total = useSelector(getTotalOrders);

    return (
        <Card className={styles.orders}>
            <HeaderTitle title='Orders'/>
            <OrderTable data={orders} emptyMessage='No order was found'/>

            {!!orders.length && (
                <Paginator pagination={pagination} totals={total} setPage={setPagination}/>
            )}
        </Card>
    )
}