import React, { FunctionComponent, useRef } from "react"
import styles from './menu.module.scss';
import Logo from '../../../assets/images/logo.png';
import { MenuItem } from "../../../App";
import { Link, useLocation } from "react-router-dom";
import { isAuthorized } from "../../../utils";
import { getLoggedUser } from "../../../store/authSlice";
import { getMenuIsOpen, toggleMenuIsOpen } from "../../../store/appSlice";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";
import useOnClickOutside from "../../../hooks/useOnclickOutside";

interface Props {
    routes: MenuItem[]
}

export const Menu: FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const loggedUser = useSelector(getLoggedUser)!;
    const isOpened = useSelector(getMenuIsOpen);
    const location = useLocation();

    const ref = useRef(null);

    const toggleOpened = () => dispatch(toggleMenuIsOpen());

    useOnClickOutside(ref, () => {
        if (!isOpened)
            return;

        toggleOpened();
    });

    return (
        <>
            <div className={`${styles.menu} ${isOpened ? styles.isOpened : ''}`} ref={ref}>
                <header>
                    <img src={Logo} alt="Logo Valtan"/>
                </header>
                <div className={styles.routes}>
                    {props.routes
                        .filter(x => isAuthorized(loggedUser, x.authorized))
                        .map((menu, key) => {
                            const isActive = location.pathname.includes(menu.path);

                            return (
                                <Link key={key} className={isActive ? styles.active : ''} to={menu.path} onClick={toggleOpened}>
                                    <img src={menu.icon} alt=''/>
                                    <span>{menu.label}</span>
                                </Link>
                            );
                        })}
                </div>
            </div>
        </>
    );
}
