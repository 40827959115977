import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { CreateUserDto, UpdateUserDto, User } from '../../../types/User';
import { Field } from '../../utils/field';
import styles from './base-form-user.module.scss';
import { Button } from "../../utils/button";
import { userIsEqual } from "../../../utils";

interface Props {
    value?: User | null;
    labelAction: string;
    onAction: (obj: CreateUserDto | UpdateUserDto) => void;
}

export const BaseFormUser: FunctionComponent<Props> = props => {
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        if (!props.value)
            return;

        setEmail(props.value.email);
        setPhoneNumber(props.value.phoneNumber);
        setName(props.value.name);
    }, [props.value]);


    const userPart: CreateUserDto | UpdateUserDto = {
        id: props.value?.id,
        name,
        email,
        phoneNumber
    };
    const didChange = !userIsEqual(userPart, props.value)
    const isActionDisabled = !email || !phoneNumber || !name || !didChange;

    const handleAction = useCallback(() => props.onAction(userPart),
        [email, phoneNumber, name]);

    return (
        <div className={styles.baseFormUser}>
            <Field
                label="Name"
                placeholder="John Travolta"
                type="text"
                required={true}
                onChange={setName}
                value={name}
                className={styles.field}
            />

            <Field
                label='Email'
                placeholder='jhonDoe@icloud.com'
                type="email"
                required={true}
                onChange={setEmail}
                value={email}
                className={styles.field}
            />

            <Field
                label='Phone Number'
                placeholder='5511931093258'
                type="phoneNumber"
                required={true}
                onChange={setPhoneNumber}
                value={phoneNumber}
                className={styles.field}
            />

            <div className={styles.actions}>
                <Button
                    className={styles.button}
                    label={props.labelAction}
                    disabled={isActionDisabled}
                    onClick={handleAction}/>
            </div>
        </div>
    );
};
